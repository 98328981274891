import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import DecelerationVsTime from "../charts/DecelerationVsTime";
import DecelerationVsSpeed from "../charts/DecelerationVsSpeed";
import DecelerationVsDistance from "../charts/DecelerationVsDistance";
import DistanceVsTime from "../charts/DistanceVsTime";
import SpeedVsTime from "../charts/SpeedVsTime";
import * as axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import PolynomialRegression from 'ml-regression-polynomial';
import * as Polynomial from 'polynomial'
import { calculateCriticalPoints } from 'polynomial-solver'
import DirectionalCompliance from "../charts/DirectionalCompliance";
import GroundSpeed from "../charts/GroundSpeed";
import AirSpeed from "../charts/AirSpeed";
import HeightAGL from "../charts/HeightAGL";
import LandingBanner from "./LandingBanner"
import { useSelector } from 'react-redux'
import { Box } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { strToFx } from "../lib/strToFx";
import { strToSolvingEquation, getCoefficients } from "../lib/strToSolvingEquation";
import findRoots from 'poly-roots'
import MLDecelerationVsDistance from "../charts/MLDecelerationVsDistance";
import { useHistory } from 'react-router-dom';
import RestrictedMLAccess from "../lib/RestrictedMLAccess";

const WEBAPI = process.env.REACT_APP_WEB_API;
// const FEET_PER_METER = 3.28084
// const GRAVITATIONAL_ACCELERATION = -9.80665
// const KNOTS = 1.94384

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(2)
  },
  chart: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  flightgrid: {
    flexGrow: 1
  },
  banner: {
    margin: theme.spacing(2),
  },

}));

// const getUTMDistance = (utmPt1, utmPt2) => {
//   const distance = +Math.sqrt( Math.pow(utmPt1[0]-utmPt2[0], 2) + Math.pow(utmPt1[1]-utmPt2[1], 2) ).toFixed(4)
//   return distance
// }

const slope_ht = 50;  // height in feet 
const slope_distance = Math.floor(slope_ht / Math.tan(3 * Math.PI/180)) // distance in feet
const GRAVITATIONAL_ACCELERATION = -9.80665;

export default function Analysis() {
    const { id } = useParams()
    const classes = useStyles();
    const history = useHistory();
    const [decelerationVsSpeedData, setDecelerationVsSpeedData] = useState([])
    const [decelerationVsTimeData, setDecelerationVsTimeData] = useState([])
    const [decelerationVsDistanceData, setDecelerationVsDistanceData] = useState([])
    const [distanceVsTimeData, setDistanceVsTimeData] = useState([])
    const [polyVsTimeData, setPolyVsTimeData] = useState([])
    const [speedVsTimeData, setSpeedVsTimeData] = useState([])
    const [speedVsDistanceData, setSpeedVsDistanceData] = useState([])
    const [airSpeedVsDistanceData, setAirSpeedVsDistanceData] = useState([])
    const [speedVsTimeFunction, setSpeedVsTimeFunction] = useState('')
    const [touchdownPoint, setTouchdownPoint] = useState(0)
    const [touchdownTime, setTouchdownTime] = useState(0)
    const [glideSlopeTouchdownPoint, setGlideSlopeTouchdownPoint] = useState(0)
    const [heightAGL, setHeightAGL] = useState(0)
    const [runwayLength, setRunwayLength] = useState(0)
    const [runwayWidth, setRunwayWidth] = useState(0)
    const [windSpeed, setWindSpeed] = useState(0)
    const [windDirection, setWindDirection] = useState(0)
    const [polyFunction, setPolyFunction] = useState('')
    const [loading, setLoading] = useState(true)
    const [airportName, setAirportName] = useState('')
    const [airportCode, setAirportCode] = useState('')
    const [landingDate, setLandingDate] = useState('')
    const [landingRunway, setLandingRunway] = useState('')
    const [icao, setIcao] = useState('')
    const [callsign, setCallsign] = useState('')
    const [directionalComplianceData, setDirectionalComplianceData] = useState([])
    const [crossWind, setCrossWind] = useState(0)
    const [crossWindDir, setCrossWindDir] = useState(0)
    const [headWind, setHeadWind] = useState(0)
    const [tailWind, setTailWind] = useState(0)
    const [_7sPastThreshold, set7sPastThreshold] = useState(0)
    const [sourceWeatherData, setSourceWeatherData] = useState([])
    const [landingData, setLandingData] = useState([])
    const [prophidden, setHidden] = useState(false)
    const [pattern, setPattern] = useState(null)

    // ml values
    const [mlMaxDecel, setMLMaxDecel] = useState(0)
    const [mlMaxDecelDelta, setMLMaxDecelDelta] = useState(0)
    const [mlCurveRMS, setMLCurveRMS] = useState(0)

    const [mlDist, setMLDist] = useState([])
    const [mlGtAccel, setMLGtAccel] = useState([])
    const [mlPredAccel, setMLPredAccel] = useState([])

    const [alerts, setAlerts] = useState(0)

    const root = useSelector((state) => state.root.value)
    const role = useSelector((state) => state.role.value)


      // var found = false;
      // var decelAtTouchDownIndex = 0;
      // var predictedTimeAtTouchdown;
      // for ( var i = 0; i < decelerationVsTimeData.length; i++ ) {
      //   if ( decelerationVsTimeData[i].deceleration === decelAtTouchDown ) {
      //     decelAtTouchDownIndex = i;
      //     found = true
      //     break
      //   }
      // }

      // if ( !found ) {
      //   const etAtMaxDecel = decelerationVsTimeData.find((d) => d.deceleration === maxDeceleration).et;

      //   var dataTillMaxDecel = decelerationVsTimeData.filter((d) => d.et <= etAtMaxDecel);
      //   const minDeceleration = Math.min(...dataTillMaxDecel.map((d) => d.deceleration))
      //   const etAtMinDecel = dataTillMaxDecel.find((d) => d.deceleration === minDeceleration).et;
      //   dataTillMaxDecel = dataTillMaxDecel.filter((d) => d.et >= etAtMinDecel);

      //   const x = dataTillMaxDecel.map((d) => d.deceleration);
      //   const y = dataTillMaxDecel.map((d) => d.et);

      //   const regression = new PolynomialRegression(x, y, 5);
      //   predictedTimeAtTouchdown = regression.predict(decelAtTouchDown);
      // } else {
      //   predictedTimeAtTouchdown = decelerationVsTimeData[decelAtTouchDownIndex].et;
      // }

      // console.log('Max Decel:', maxDeceleration)
      // console.log('Calculated decel at TouchDown:', decelAtTouchDown)
      // console.log('Predicted Time at TouchDown:', predictedTimeAtTouchdown)

      // const predictDistance = strToFx(polyFunction);
      // const predictSpeed = strToFx("f(x)="+speedVsTimeFunction);

      // console.log('predictedSpeed', predictSpeed(predictedTimeAtTouchdown)*1.94384, 'kt')
      // console.log('predictedDistance', predictDistance(predictedTimeAtTouchdown)*3.28084, 'ft')

    useEffect(() => {
        // console.log('landingID: ', id)

        if (id !== undefined) {
          axios.get(`${WEBAPI}/landing/${id}`)
          .then((res) => {
              const d = res.data[0]
              console.log('Data:', d)

              if ( !(root || role === "admin") ) {
                if ( d.hidden === true ) {
                  // redirect to landing page
                  history.push('/landings')
                }
              }

              setAirportName(d.runway.airport.airportname)
              setAirportCode(d.runway.airport.code)
              setLandingDate(d.created)
              setLandingRunway(d.landingrunway)
              setIcao(d.icaoaddress)
              setCallsign(d.callsign)
              setHidden(d.hidden)
              setPattern(d.landing_pattern)

              setAlerts(d.landingalerts.length)

              // chartsDataPrep(d)
              setRunwayLength(d.runway.length_ft)
              setRunwayWidth(d.runway.width_ft)

              setLandingData(d.landingdata)
        
              setDistanceVsTimeData(JSON.parse(d.landinganalysis.distanceTimeData))
              setPolyVsTimeData(JSON.parse(d.landinganalysis.polyTimeData))
              setPolyFunction(d.landinganalysis.polyFunction)

              setSpeedVsTimeData(JSON.parse(d.landinganalysis.speedTimeData))
              setSpeedVsTimeFunction(d.landinganalysis.speedTimeFunction)

              setDecelerationVsSpeedData(JSON.parse(d.landinganalysis.decelerationSpeedData))
              // console.log(d.landinganalysis.decelerationSpeedData);

              setDecelerationVsTimeData(JSON.parse(d.landinganalysis.decelerationTimeData))

              setDecelerationVsDistanceData(JSON.parse(d.landinganalysis.decelerationDistanceData))

              setTouchdownPoint(d.landinganalysis.touchdown)
              setTouchdownTime(d.landinganalysis.touchdown_time)
              setGlideSlopeTouchdownPoint(d.landinganalysis.glideslope_touchdown)
              setHeightAGL(d.landinganalysis.height_agl)

              setDirectionalComplianceData(JSON.parse(d.landinganalysis.directionalComplianceSmoothedData))

              setSpeedVsDistanceData(JSON.parse(d.landinganalysis.speedDistanceData))

              // set ML values
              setMLMaxDecel(d.landinganalysis.ml_max_decel_g)
              setMLMaxDecelDelta(d.landinganalysis.ml_max_decel_delta_g)
              setMLCurveRMS(d.landinganalysis.ml_curve_rms)

              setMLDist(d.landinganalysis.ml_dist)
              setMLGtAccel(d.landinganalysis.ml_gt_accel)
              setMLPredAccel(d.landinganalysis.ml_pred_accel)

              setSourceWeatherData({
                windDirection: d.landinganalysis.wind_direction,
                windSpeed: d.landinganalysis.wind_speed,
                source: 'METAR'
              })

              const groundSpeedData = JSON.parse(d.landinganalysis.speedDistanceData)

              const airSpeed = groundSpeedData.map((el, idx) => {
                if (headWind>0) {
                  el.speed = parseFloat(el.speed) + headWind
                } else {
                  el.speed = parseFloat(el.speed) - tailWind
                }

                return el;
              })

              console.log('airSpeed: ', airSpeed)

              setAirSpeedVsDistanceData(airSpeed)

              getTouchDownTime(JSON.parse(d.landinganalysis.decelerationTimeData), d.landingdata, d.landinganalysis.polyFunction, d.landinganalysis.touchdown_time, JSON.parse(d.landinganalysis.distanceTimeData), JSON.parse(d.landinganalysis.speedDistanceData))

              setLoading(false)

            })
          .catch((err) => {
            console.log(err);
          })
        }

    }, [id]);

    useEffect(() => {
      if (sourceWeatherData.windDirection !== undefined) {
        const windDir = sourceWeatherData.windDirection
        setWindDirection(windDir)

        const windSpeed = parseFloat(sourceWeatherData.windSpeed)
        setWindSpeed(windSpeed)

        // Calculate AirSpeed Vs Distance
        console.log('windDir, windSpeed: ', windDir, windSpeed)

        let headWind=0, tailWind=0, crossWind=0, crossWindDir=0

        if (windDir === 0) { // CrossWind Only
          crossWind = windSpeed
          crossWindDir = 0
        } else if (windDir === 90) { // headWind
          headWind = windSpeed
        } else if (windDir === 180) { // CrossWind Only
          crossWind = windSpeed
          crossWindDir = 180
        } else if (windDir === 270) { // tailWind
          tailWind = windSpeed
        } else if (windDir === 360) { // CrossWind Only
          crossWind = windSpeed
          crossWindDir = 0
        } else if (windDir>0 && windDir<90) { // headWind
          headWind = Math.round( (windSpeed * Math.sin((windDir)*Math.PI/180)) * 10 )/10
          crossWind = Math.round( (windSpeed * Math.cos((windDir)*Math.PI/180)) * 10 )/10
          crossWindDir = 0
          console.log('Quadrant 1: ', headWind, crossWind, crossWindDir)
        } else if (windDir>90 && windDir<180) { // headWind
          headWind = Math.round( (windSpeed * Math.cos((windDir-90)*Math.PI/180)) * 10 )/10
          crossWind = Math.round( (windSpeed * Math.sin((windDir-90)*Math.PI/180)) * 10 )/10
          crossWindDir = 180
          console.log('Quadrant 2: ', headWind, crossWind, crossWindDir)
        } else if (windDir>180 && windDir<270) { // tailWind
          tailWind = Math.round( (windSpeed * Math.sin((windDir-180)*Math.PI/180)) * 10 )/10
          crossWind = Math.round( (windSpeed * Math.cos((windDir-180)*Math.PI/180)) * 10 )/10
          crossWindDir = 180
          console.log('Quadrant 3: ', tailWind, crossWind, crossWindDir)
        } else if (windDir>270 && windDir<360) { // tailWind
          tailWind = Math.round( (windSpeed * Math.cos((windDir-270)*Math.PI/180)) * 10 )/10
          crossWind = Math.round( (windSpeed * Math.sin((windDir-270)*Math.PI/180)) * 10 )/10
          crossWindDir = 0
          console.log('Quadrant 4: ', tailWind, crossWind, crossWindDir)
        }

        console.log('headWind, tailWind, crossWind, crossWindDir: ', headWind, tailWind, crossWind, crossWindDir)
        console.log(sourceWeatherData)
        setHeadWind(headWind)
        setTailWind(tailWind)
        setCrossWind(crossWind)
        setCrossWindDir(crossWindDir)
      }
    }, [sourceWeatherData])

    function getTouchDownTime(decelerationVsTimeData, landingData, polyFunction, est_touchdown_time, distanceVsTimeData, speedDistanceData) {

      // filter the landing data to get only the points on the runway
      const runwayData = landingData.filter((d) => {
        return d.onrunway === true;
      });


       // calculate the distance 7s after the threshold
       const thresholdTime = distanceVsTimeData[runwayData[0].idx].et;
       const fxTimeVsDistance = strToFx(polyFunction);
 
       const mPastThreshold = fxTimeVsDistance(parseFloat(thresholdTime)+7 ) - fxTimeVsDistance(thresholdTime)
       set7sPastThreshold(mPastThreshold*3.28084)


      // calculate the possible g at the touchdown point
      let currentMax = 0;
      let timeAtMax = 0;
      for ( var i = 0; i < decelerationVsTimeData.length - 10; i++ ) {
        if ( decelerationVsTimeData[i].deceleration > currentMax ) {
          currentMax = decelerationVsTimeData[i].deceleration;
          timeAtMax = decelerationVsTimeData[i].et;
        }
      }
      const maxDeceleration = currentMax;
      const decelAtTouchDown = maxDeceleration / 3.0;


       // calculate the time of first point on the runway
      const thresholdSpeed = speedDistanceData[0].speed
      const thresholdDistance = speedDistanceData[0].distance
      const timeOfFirstPoint = thresholdDistance / thresholdSpeed


      // calculate the time of touchdown
      const x = distanceVsTimeData.map((d) => d.et)
      const y = distanceVsTimeData.map((d) => d.distance)

      const regression = new PolynomialRegression(x, y, 9);
      const decelVsTimePoly = new Polynomial(regression.coefficients).derive(2)

      console.log('2nd derivative', decelVsTimePoly.toString(), '=', decelAtTouchDown, thresholdTime, timeOfFirstPoint)
      // create a function to use the 2nd derivative to create a solvable equation
      var solvingEq = strToSolvingEquation(decelVsTimePoly.toString());

      // solve the equation and get coefficients
      let coefficients = getCoefficients(solvingEq(decelAtTouchDown * GRAVITATIONAL_ACCELERATION));

      // find the roots of the equation
      let roots = findRoots(coefficients);
      let calculatedTouchDownTime = 0;

      if ( roots[0].length > 0 ) {
        for ( var i = 0; i < roots[0].length; i++ ) {
          let possibleTouchDownTime = Math.abs(roots[0][i]) - thresholdTime + timeOfFirstPoint;

          // get the first root that is between 4 seconds and the time of max deceleration
          if ( possibleTouchDownTime >= 4 && possibleTouchDownTime < timeAtMax ) {
            calculatedTouchDownTime = possibleTouchDownTime;
            break;
          }            
        }
      }

      console.log('roots: ', roots[0])
      console.log('thresholdTime: ', thresholdTime)
      console.log('timeOfFirstPoint: ', timeOfFirstPoint)
      console.log('AtMax: ', maxDeceleration, timeAtMax)
      console.log('CalculatedTouchDown: ', decelAtTouchDown, calculatedTouchDownTime)
    }


    return ( 
        <div >
            {
                loading ? 
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                  <CircularProgress color="secondary" />
                </Box>
                :
                <>
                  <Box className={classes.banner}>
                    <LandingBanner 
                      airportname={airportName} 
                      airportcode={airportCode} 
                      created={landingDate} 
                      landingrunway={landingRunway} 
                      icaoaddress={icao} 
                      callsign={callsign} 
                      id={id} 
                      setSourceWeatherData={setSourceWeatherData} 
                      prophidden={prophidden} 
                      alerts={alerts} 
                      pattern={pattern}/>
                  </Box>

                  <RestrictedMLAccess>
                    { mlMaxDecel && <div className={classes.chart}>
                      <MLDecelerationVsDistance root={root} data={decelerationVsDistanceData} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} length={runwayLength} mlMaxDecel={mlMaxDecel} mlMaxDecelDelta={mlMaxDecelDelta} mlCurveRMS={mlCurveRMS} mlDist={mlDist} mlGtAccel={mlGtAccel} mlPredAccel={mlPredAccel} />
                    </div> }
                  </RestrictedMLAccess>

                  {
                    root ?
                    <>

                    <div className={classes.chart}>
                      <DistanceVsTime data={distanceVsTimeData} poly={polyVsTimeData} polyFunction={polyFunction} />
                    </div>

                    <div className={classes.chart}>
                      <SpeedVsTime data={speedVsTimeData} function={speedVsTimeFunction} />
                    </div>

                    </>
                    :
                      null
                  }
                  
                  <div className={classes.chart}>
                    <DecelerationVsSpeed data={decelerationVsSpeedData} />
                  </div>

                  <div className={classes.chart}>
                    <DecelerationVsTime root={root} data={decelerationVsTimeData} touchdownTime={touchdownTime}  />
                  </div>

                  <div className={classes.chart}>
                    <DecelerationVsDistance root={root} data={decelerationVsDistanceData} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} length={runwayLength} />
                  </div>

                  <div className={classes.chart}>
                    <DirectionalCompliance root={root} data={directionalComplianceData} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} length={runwayLength} width={runwayWidth} wind_direction={windDirection} wind_speed={windSpeed} source={sourceWeatherData.source} />
                  </div>

                  <div className={classes.chart}>
                    <GroundSpeed root={root} data={speedVsDistanceData} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} length={runwayLength} />
                  </div>

                  <div className={classes.chart}>
                    <AirSpeed root={root} data={airSpeedVsDistanceData} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} length={runwayLength} headwind={headWind} tailwind={tailWind} crosswind={crossWind} crosswinddir={crossWindDir} source={sourceWeatherData.source} />
                  </div>

                  <div className={classes.chart}>
                    <HeightAGL root={root} touchdown={touchdownPoint} glideslope_touchdown={slope_distance} heightAGL={heightAGL} length={runwayLength} slope_ht={slope_ht} slope_distance={slope_distance} pastThreshold={_7sPastThreshold} />
                  </div>

                </>
            }
        </div>
     );
}

